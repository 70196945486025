import icon1 from "../../assets/icon-1.svg"
import icon1Orange from "../../assets/icon-1-O.svg"
import icon2 from "../../assets/icon-2.svg"
import icon3 from "../../assets/icon-3.svg"
import icon3Green from "../../assets/icon-3-G.svg"
import icon4 from "../../assets/icon-4.svg"
import icon5 from "../../assets/icon-5.svg"
import icon6 from "../../assets/icon-6.svg"
import icon7 from "../../assets/icon-7.svg"


export const services = [
  {
    id: 1,
    clase:"icon1",
    icon: icon7,
    title: "Staffing",
    body: "Finding the right talent to support your business can be daunting. With our deep expertise and extensive network, we can help you find the right fit for your organization, in a time frame you will appreciate.",
    detalles: [
      {text: "Process Outsourcing"},
      {text: "Temporary or Permanent placements"},
      {text: "Speciality skill placements"},
    ],
  },
  {
    id: 3,
    clase:"icon3 icon-size-wide",
    icon: icon1Orange,
    title: "Development",
    body: "If you're looking for a proven delivery partner, who understands the nuances of keeping up with the market while managing costs, welcome home. \r\n\n Leverage our experience across platforms, industries, methodologies, and technologies to drive the results your business needs, in a cost-effective, predictable, and secure way",
    detalles: [
      {text: "Website, Application, & Mobile development"},
      {text: "Enterprise Integration"},
      {text: "Migration to/from the Cloud"},
      {text: "ECommerce, Identity Management, Customer Portal, and so much more"},
    ],
  },
  {
    id: 4,
    clase:"icon1",
    icon: icon3Green,
    title: "Testing",
    body: "Getting it right the first time, every time, has become table stakes. Whether you are looking to enhance your brand, guarantee an ideal user experience, or protect the critical data of your business, we have the skills to help you succeed.",
    detalles: [
      {text: "Manual/Automated Testing"},
      {text: "Security & Privacy Certified "},
      {text: "Automation CI/CD"},
      {text: "Security, Performance, Scalability"},
    ],
  },
  {
    id: 5,
    clase:"icon2",
    icon: icon5,
    title: "Devops",
    body: "The first version is always the hardest, right? Then comes making sure your solution scales gracefully, reliably, efficiently, and securely under the constant pressures of production, market demand, and cost management.\r\n\n Whether you are beginning to explore options, have a mature internal practice, or anywhere in between, it's likely we can help you grow your capabilities, redeploy your human capital, and keep your margins headed in the right direction",
    detalles: [
      {text: "CI/CD Creation, Management, Migration"},
      {text: "AWS, GCP, Azure"},
      {text: "Active assurance"},
    ],
  },
  {
    id: 6,
    clase:"icon3",
    icon: icon6,
    title: "BUSINESS INTELLIGENCE",
    body: "Timely, accurate, and actionable data can be the deciding factor in so many aspects of life and business. \r\n\n Our experience mining, aggregating, curating, distilling, visualizing, and speaking to your business in motion are second to none. As with our other disciplines, we can provide value, capacity, and expertise regardless of your current state.",
    detalles: [
      {text: "Data collection, curation, and analysis"},
      {text: "Data Visualization"},
    ],
  },
  {
    id: 7,
    clase:"icon1",
    icon: icon4,
    title: "Consulting",
    body: "Whether exploring new marketplaces, launching product lines, optimizing existing solutions, transforming your enterprise, or beyond we have the expertise you need. \r\n\n Let's engage in a thoughtful and analytical conversation around how to help your business reach its next level of success",
    detalles: [
      {text: "Process evaluation and optimization"},
      {text: "Product Introduction or Evolution"},
      {text: "Infrastructure strategy "},
    ],
  },
];
