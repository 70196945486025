import React from "react";
import "./headeraboutus.css";
import "aos/dist/aos.css";

const HeaderAboutUs = () => {
  return (
    <section className="about-section">
      <div className="container-header-about">
        <div className="img-background-headeraboutus"/>
        <div className="container-headeraboutus" >
            <h2 className="h-title">About us</h2>
            <p className="card-text">
            Excelient is a premier on-shore, off-shore and near-shore software development and BPO company. We have extensive experience in providing highly-skilled resources and successful project-based delivery from a global market that work with your existing team members to deliver complex, custom full-stack and multi-platform solutions.
            </p>
        </div>
      </div>
    </section>
  );
};

export default HeaderAboutUs;