import React from "react";
import { profesionals } from "./data";
import ProfesionalCard from "./profesionalcard";
import "./profesionaltalent.css"
import "aos/dist/aos.css";

const ProfesionalTelent = () => {
  return (
    <section className="profesional-background">
      <div className="container">
        <div className="u-title" data-aos="fade-up">
          <p className="u-text-small-green"> Feel Free To </p>
          <h1 className="h-title">Contact Us</h1>
          <p className="u-text-small-white">
            Excelient is a premier on-shore, off-shore and near-shore software development and BPO company. 
          </p>
        </div>
        
       {/* <div className="container-profesional">
          {profesionals.map((profesional) => (
            <ProfesionalCard
              key={profesional.id}
              index = {profesional.id}
              title={profesional.title}
              body={profesional.body}
              image={profesional.image}
            />
          ))}
          </div>  */}
      </div>
    </section>
  );
};

export default ProfesionalTelent;
