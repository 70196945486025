import React, {useRef} from "react";
import './ContactUs.css';
import emailjs from '@emailjs/browser';

const ContactUs = () => {
    const form = useRef();
    const SendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm(
            'service_kx6vafk', 
            'template_up1v6zg', 
            form.current, 
            'tSiQQSAWCc4u6ytFB')
          .then((result) => {
              console.log(result.text);
          }, (error) => {
              console.error(error.text);
          });
          e.target.reset();
    };

    return (
        <section>
                <div className="background-image" >
                    <div className="leblanc"/>
                    <div className="container-card">
                        <div className="div-inputs-container">
                            <div className="title">
                                <h1 className="h-title">Contact Us</h1>
                                <p className="p-title">Stay Connected with Excelient</p>
                            </div>
                            <form ref={form} onSubmit={SendEmail} className="form-contactus">    
                            <input type="text" name="fullname" placeholder="Full name" className="form-contactus-fields" required={true}/>
                            <input type="email" name="email" placeholder="E-mail" className="form-contactus-fields" required={true}/>
                            <input type="text" name="message" placeholder="Message" className="form-contactus-fields" required={true}/>
                            <div className="div-button-contactus">
                                <input type="submit" className="btn-dark btn-contactus" value="Send Message" />
                            </div>
                            </form>
                        </div>
                        <div className="offices-container">
                            <div className="office-container">
                                <h1>US Office</h1>
                                <p>26901 Agoura RD,</p>
                                <p>Suite 160,</p>
                                <p>Calabasas, CA 91301</p>
                            </div>
                            <div className="office-container">
                                <h1>India office</h1>
                                <p>Unit No. 1, SDF-1, SEEPZ SEZ, Andheri</p>
                                <p>(East), Mumbai, India, 400096</p>
                            </div>
                            <div className="office-container">
                                <h1>México office</h1>
                                <p>Paseo de Las Quintas 101</p>
                                <p>Hermosillo, Sonora, MX 83245</p>
                            </div>
                            <div className="office-container">
                                <h1>Business development</h1>
                                <p>Kelly Ross</p>
                                <p>Call: +1.360.607.2071</p>
                                <p>Email: kross@excelient.com</p>
                            </div>
                        </div>
                    </div>
                </div>        
        </section>
    );
};

export default ContactUs;