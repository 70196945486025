import React from "react";
import  HeaderAboutUs from "../../components/headeraboutus/headeraboutus";
import LocationsAndResources from "../../components/locationsandresources/locationsandresources"
import ProfesionalTalent from "../../components/profesionaltalent/profesionaltalent"


const AboutUsPage = () => {
    return (
        <>
            <HeaderAboutUs />
            <LocationsAndResources />
            <ProfesionalTalent />
        </>
    );
};

export default AboutUsPage;
