import React from "react";
import "./Services.css";

const Services = ({ clase, icon, title, body, detalles, containerClass }) => {
    return (
    <div className={`container service${containerClass}`}>
      <div className="service-title">
        <img src={icon} alt="MDN" className={`${clase} icon-size`} />
      </div>
      <div className="service-title">
        <div className="title-desc-services"><h4>{title}</h4></div>
      </div>
      <div className={`service-answer${containerClass}`}>
         <p className={`u-text-small${containerClass} smaller breakline`}>{body}</p>
      </div>
      <div className="service-links">
      {detalles.map((detalle) => (
            <a className="equis">&#x2022;{detalle.text}</a>
          ))}
      </div>
    </div>
  );
};

export default Services;