import React from "react";
import './GeographyCard.css';
import { FaMapMarkerAlt, FaHandshake } from "react-icons/fa";


const Card = ({ side, img, title, locationTitle, locations, engagementModels }) => {
    return (
       <div className={`card-Container${side}`}> 
            <div className={`card-img-container${side}`}>
                <img src={img}></img>
            </div>
            <div className={`card-info-container${side}`}>
                <p className="card-info-title">{title}</p>
                <h2 className="card-location-title">{locationTitle}</h2>

                <div className="div-location">
                    { locations.map((location) =>(
                        <div key={"locations" + location.namedesc}>
                            <FaMapMarkerAlt className="icon"/>
                            <span className="span-description">{location.namedesc}</span>
                        </div>
                    ))}
                </div>
            </div>
       </div>     
    );
};

export default Card;