import icon1 from "../../assets/bicycle-solid.svg"
import icon2 from "../../assets/flask-solid.svg"
import icon3 from "../../assets/lock-solid.svg"

export const services = [
  {
    id: 1,
    clase:"icon1 icon-size-wide",
    icon: icon1,
    title: "Come enjoy the sun",
    body: "We helped a US city launch a bike rental solution for their residents and tourists \r\n\n Innovative and green solution covering registration, payment processing, and unit management of a bike rental platform",
    detalles: [],
  },
  {
    id: 2,
    clase:"icon2",
    icon: icon2,
    title: "Driving results in the lab",
    body: "A healthcare provider was experiencing challenges with sample quality and timeliness \r\n\n A multi platform solution lead to 25% efficiency gains overall, and >50% reduction in manual handling",
    detalles: [],
  },
  {
    id: 3,
    clase:"icon3",
    icon: icon3,
    title: "Protecting the Enterprise",
    body: "Through an audit, automation, and educational engagement we assisted a leading DSP optimize their delivery cycle (>50% improvements) without compromising on quality or security.",
    detalles: [],
  },
];
