import US from "../../assets/USMap.svg";
import MX from "../../assets/MexicoMap.svg";
import IN from "../../assets/IndiaMap.svg";

export const CardsData = [
    {
      id: 1,
      title: "On-shore",
      locationTitle: "US 50+",
      img: US,
      side: "",
      locations: [
        {
          namedesc: "Calabasas, CA",
        }
      ],
      engagementModels: [
        {
          model: "Short term",
        },
        {
          model: "Long term",
        }
      ]
    },
    {
      id: 2,
      title: "Near-shore",
      locationTitle: "México 50+",
      img: MX,
      side: "-reverse",
      locations: [
        {
          namedesc: "Guadalajara",
        },
        {
          namedesc: "Hermosillo",
        }
      ],
      engagementModels: [
        {
          model: "Short term",
        },
        {
          model: "Long term",
        }
      ]
    },
    {
      id: 3,
      title: "Off-shore",
      locationTitle: "India 5000+",
      img: IN,
      side: "",
      locations: [
        {
          namedesc: "Hyderabad",
        },
        {
          namedesc: "Bangalore",
        },
        {
          namedesc: "Noida (future)"
        }
      ],
      engagementModels: [
        {
          model: "Short term",
        },
        {
          model: "Long term",
        }
      ]
    },
  ];