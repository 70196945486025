import "./App.css";
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";

import {
  Navbar,
  AboutUsPage,
  Footer,
  HomePage,
  OurServices,
  ContactUs,
  GeographyPage,
  PrivacyPolicy,
} from "./components";

function App() {
  return (
    <>
    <BrowserRouter>
      <Navbar />
          <Routes>
            <Route  exact path="/" element={<HomePage />} />
            <Route path="/aboutus" element={<AboutUsPage />} />
            <Route path="/services" element={<OurServices />} />
            <Route path="/contactus" element={<ContactUs />} />
            <Route path="/geography" element={<GeographyPage />} />
            <Route path="/privacypolicy" element={<PrivacyPolicy />} />
          </Routes>
        </BrowserRouter>
      <Footer />
    </>
  );
}

export default App;
