import React from "react";
import './Geographypage.css';
import GeographyCard from './GeographyCard';
import { CardsData } from "./data";

const GeographyPage = () => {
    return (
        <div className="container">
            <div className="header-container-geo">
                <p className="h-title-geo">
                    Geography
                </p>
            </div>

            <div className="cards-container">
            {CardsData.map((cardData) => (
              <GeographyCard
                key={cardData.id}
                side ={cardData.side}
                img = {cardData.img}
                title={cardData.title}
                locationTitle={cardData.locationTitle}
                locations={cardData.locations}
                engagementModels={cardData.engagementModels}
              />
            ))}
            </div>
        </div>
    );
};

export default GeographyPage;