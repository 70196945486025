import React, { useState } from "react";
import "./proposition.css";

const Proposition = ({ index, title, body }) => {
  return (
    <div className="container proposition">
      <div className="proposition-title">
      </div>
      <div className="proposition-title">
        <h4>{title}</h4>
      </div>
      <div className="proposition-answer">
        <p className="u-text-small breakline">{body}</p>
      </div>
    </div>
  );
};

export default Proposition;
