export const propositions = [
  {
    id: 1,
    title: "PROVEN SUCCESS",
    body: "From startups to Fortune 500, we know how to deliver "+"\r\n\n"+" Providing leadership and support across multiple disciplines, let us show you why our clients call us partners not providers",
  },
  {
    id: 2,
    title: "INNOVATION LABS",
    body:
      "We are digital enablers, relentlessly pursuing technology evolution "+"\r\n\n"+" This commitment enables you to choose the right solution and approach, based on your environment, while minimizing risks and costs",
  },
  {
    id: 3,
    title: "DELIVERY EFFICIENCY",
    body:
      "Time is money "+"\r\n\n"+" Our best-in-class methodologies ensure a rapid and successful delivery, or placement, at the pace your business demands.",
  },
  {
    id: 4,
    title: "MATURE COEs",
    body:
      "Trust is earned, not given "+"\r\n\n"+" Our Centers of Excellence are collections of hand picked staff, with proven expertise in areas that matter most to our clients.",
  },
  {
    id: 5,
    title: "OPTIMAL SCALE",
    body:
      "The perfect balance of reach, capacity, and personalization to care for your business "+"\r\n\n"+" Delivery centers across multiple continents enable flexible engagement and support models.",
  },
  {
    id: 6,
    title: "QUALITY TALENT",
    body:
      "The right resource makes all the difference "+"\r\n\n"+" Whether you need an industry SME, smart hands for delivery or support, or are looking to add to your staff we have the experts you need.",
  },
  // {
  //   id: 7,
  //   title: "Tier 1 Clientele & Proven Experience ",
  //   body:
  //     "Experience of Kaiser Permanente, Goldman Sacs, Charter, Dish and DirecTV.."+"\r\n"+"\n"+" We have been a part of each client's design, development, testing, and integration process and their teams.",
  // },
];
