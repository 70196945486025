import React, { useState } from "react";
import { AiOutlineBars } from "react-icons/ai";
import { RiCloseLine } from "react-icons/ri";
import Button from "../UI/Button/Button";
import "../UI/Button/Button.css";
import logo from '../../assets/excelient-logo.svg';
import "./Navbar.css";
import { NavLink } from "react-router-dom";

const Navbar = () => {
  const [showMenu, setShowMenu] = useState(false);

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };
  return (
    <nav className="navbar container_nav">
      <div className="logo">
        <img src={logo} alt="Logo" />
      </div>
      <menu>
        <ul
          className="nav-links"
          id={showMenu ? "nav-links-mobile" : "nav-links-mobile-hide"}
        >
          <li>
            <NavLink to="/" className={({ isActive }) => (isActive ? "nav-home" : "")}>Home</NavLink>
          </li>
          <li>
            <NavLink to="/aboutus" className={({ isActive }) => (isActive ? "nav-home" : "")}>About us</NavLink>
          </li>
          <li>
            <NavLink to="/services" className={({ isActive }) => (isActive ? "nav-home" : "")}>Services</NavLink>
          </li>
          <li>
            <NavLink to="/geography" className={({ isActive }) => (isActive ? "nav-home" : "")}>Geography</NavLink>
          </li>

          {/* <li>
            <a href="#" className="btn btn-dark">
              Get Started
            </a>
          </li> */}
          <li className="nav-btn">
            <Button text={"CONTACT US"} btnClass={"btn-dark"} href={"/contactus"} />
          </li>
        </ul>
      </menu>
      <div className="menu-icons" onClick={toggleMenu}>
        {showMenu ? (
          <RiCloseLine color="#fff" size={30} />
        ) : (
          <AiOutlineBars color="#fff" size={27} />
        )}
      </div>
    </nav>
  );
};

export default Navbar;
