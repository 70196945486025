import React from "react";
import { services } from "../../components/datasets/servicecardsdata-two";
import "./Ourservice.css";

import {
    ServiceCards,
    Services,
  } from "../../components";


const OurServices = () => {
    return (
        <>
        <section className="section-bchgrnd">
            <div className="container-bckgrnd">
                <div className="container header center">
                    <h1 className="h1-ourservices">
                        <span>Our Services</span>
                    </h1>
                    <h3 className="h3-text-small">
                    Excelient is your premier multi-shore BPO, ITO, and consulting firm
                    </h3>
                </div>
            </div>
                <div className="container-proposition-os">
                {services.map((service) => (
                    <Services
                    key={service.id}
                    clase = {service.clase}
                    icon = {service.icon}
                    title={service.title}
                    body={service.body}
                    detalles={service.detalles}
                    containerClass={'-w'}
                    />
                ))}
                </div>
        </section>
        </>
    );
};

export default OurServices;
 /*                 <div className="header-left header-title" data-aos="fade-right">
                <span className="sm-title">Sma</span>
                <h1>
                    <span>Help you to digitize<br/>your business</span>
                </h1>
                <p className="u-text-small">
                Lorem ipsum dolor sit amet consectetur adipiscing elit Ut et massa mi.
                Aliquam in hendrerit urna. Pellentesque sit amet sapien fringilla, 
                mattis ligula consectetur,
                </p>
                </div>*/