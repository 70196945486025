import React from "react";

import {
    Header,
    Whoweare,
    ValueProposition,
    ServiceCards,
  } from "../../components";

const HomePage = () => {
    return (
        <>
        <header className="header-bg">
            <Header data-aos="fade-left"/>
            <ServiceCards data-aos="fade-right"/>
            <Whoweare data-aos="fade-up"/>
            <ValueProposition />
        </header>
        </>
    );
};

export default HomePage;
