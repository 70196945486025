import React from "react";
import "./Whoweare.css";
import Button from "../UI/Button/Button";
import "aos/dist/aos.css";

const Whoweare = () => {
  return (
    <section className="section-background2">
        <div className="container-diamond">
            <div className="img-background"/>
            <div className="inner-container">
                <h2 className="h-title">WHO WE ARE</h2>
            <p className="card-text">
            A premier multi-services firm, with a passion for exceptional delivery, and a 20 year legacy of mutual success.
            </p>
            <div className="card-footer-button">
              <Button text={"LEARN MORE ABOUT US"} btnClass={"btn-dark"} href={"/contactus"} />
            </div>
            </div>
        </div>
      </section>
  );
};

export default Whoweare;
