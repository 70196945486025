import React from "react";
import PrivacyPolicyContent from './content'

const PrivacyPolicy = () => {
    return (
        <>
        <header >
            <PrivacyPolicyContent />
        </header>
        </>
    );
};

export default PrivacyPolicy;