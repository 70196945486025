import React, { useEffect } from "react";
import "./Header.css";
import "../UI/Button/Button.css";

import AOS from "aos";
import "aos/dist/aos.css";

const Header = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);
  return (
    <section id="header">
      <div className="container header">
        <div className="header-left header-title" data-aos="fade-right">
          <h1>
            <span>
              Enabling your digital journey
            </span>
          </h1>
          <p className="u-text-small long">
            Our expert staff is ready to help you deliver amazing results
          </p>
        </div>
      </div>
    </section>
  );
};

export default Header;
