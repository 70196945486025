import React, { useEffect } from "react";
import Button from "../UI/Button/Button";
import "./locationsandresources.css"
import AOS from "aos";

const LocationsAndResources = () => {
    useEffect(() => {
      AOS.init({
        duration: 1000,
      });
    }, []);
    return (
      <section className="section-background3">
        <div className="container">
          <div className="u-title" data-aos="fade-up">
            <h1 className="h-title-locations">LOCATIONS AND RESOURCES</h1>
            <p className="u-text-small-white-locations">
                Excelient is a premier on-shore, off-shore and near-shore software development and BPO company.
            </p>
          </div>
          
          <div className="container-locations">
          </div>
        </div>

        <div className="divButton"><Button text={"LEARN MORE ABOUT OUR LOCATIONS"} btnClass={"btn-dark"} href={"/geography"} /></div>
        
      </section>
    );
  };

  export default LocationsAndResources;