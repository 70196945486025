import React from "react";
import { propositions } from "./data";
import Proposition from "./proposition";
import "./valueproposition.css";
import Button from "../UI/Button/Button";
import "aos/dist/aos.css";

const ValueProposition = () => {
  return (
    <section className="section-background">
      <div className="u-title-proposition" data-aos="fade-up">
        <h1 className="h-title">Why Excelient</h1>
        <div className="u-text">
          <p className="u-text-small-white">
            With 15 years industry experience, we bring a vast knowledge of Delivering small, medium and large scale projects for the top 5 Telcos and Cablecos in North America.
          </p>
        </div>
      </div>
      <div className="container-proposition">
        {propositions.map((proposition) => (
          <Proposition
            key={proposition.id}
            index={proposition.id}
            title={proposition.title}
            body={proposition.body}
          />
        ))}
      </div>
      <div className="container-button">
        <Button text={"Learn More"} btnClass={"btn-dark"} href={"/aboutus"} />
      </div>
    </section>
  );
};

export default ValueProposition;
