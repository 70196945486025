import React from "react";
import { services } from "../datasets/servicecardsdata-one";
import Services from "../services/Services";
import "./servicecards.css"
import Button from "../UI/Button/Button";
import "aos/dist/aos.css";

const ServiceCards = () => {
  return (
    <section className="section-background2">
     <div className="container-section-home">
     <div className="container-section-home-title-section"><h1>Sample Use Cases</h1></div>


        <div className="container-proposition">
          {services.map((service) => (
            <Services
              key={service.id}
              clase = {service.clase}
              icon = {service.icon}
              title={service.title}
              body={service.body}
              detalles={service.detalles}
              containerClass={''}
            />
          ))}
        </div>
     </div>
      <div className="container-button">
        <Button text={"Learn More About Our Services"} btnClass={"btn-dark"} href={"/services"} />
      </div>
    </section>
  );
};

export default ServiceCards;
